import React, { Component } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class PadelCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      filteredAppointments: [],
      searchQuery: "",
      selectedDate: null,
      currentPage: 1,
      eventsPerPage: 12,
      showResults: false,
      eventDates: [], // Store the dates with available events
    };
  }

  componentDidMount() {
    this.fetchAppointments();
  }

  fetchAppointments = () => {
    // Your API URL
    const apiUrl = "https://eventschedulerapi.junglepadel.com/";

    // Basic Auth credentials
    const username = "jungleuluwatu";
    const password = "Jungleuluwatu123!!!_2!";
    const basicAuth = "Basic " + btoa(username + ":" + password);

    const options = {
      headers: {
        Authorization: basicAuth,
      },
    };

    axios
      .get(apiUrl, options)
      .then((response) => {
        console.log("API Response:", response.data);
        const eventDates = response.data.map((appointment) =>
          new Date(appointment.start_date).setHours(0, 0, 0, 0)
        );
        this.setState({
          appointments: response.data,
          filteredAppointments: response.data,
          showResults: true, // Display the appointments by default
          eventDates: eventDates,
        });
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  };

  handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({ searchQuery });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    if (date) {
      const filteredAppointments = this.state.appointments.filter(
        (appointment) =>
          new Date(appointment.start_date).toDateString() ===
          date.toDateString()
      );
      this.setState({ filteredAppointments, showResults: true });
    } else {
      this.setState({ filteredAppointments: this.state.appointments });
    }
  };

  handleFilterByMonth = () => {
    const currentMonth = new Date().getMonth() + 1;
    const filteredAppointments = this.state.appointments.filter(
      (appointment) =>
        new Date(appointment.start_date).getMonth() + 1 === currentMonth
    );
    this.setState({ filteredAppointments, showResults: true }); // Show results after filtering
  };

  handleFilterByDay = () => {
    const currentDate = new Date().getDate();
    const filteredAppointments = this.state.appointments.filter(
      (appointment) =>
        new Date(appointment.start_date).getDate() === currentDate
    );
    this.setState({ filteredAppointments, showResults: true }); // Show results after filtering
  };

  handleReset = () => {
    this.setState({
      filteredAppointments: this.state.appointments,
      searchQuery: "",
      selectedDate: null,
      currentPage: 1,
      showResults: true, // Show results after resetting
    });
  };

  handleFindEvents = () => {
    const { searchQuery, selectedDate } = this.state;
    let filteredAppointments = this.state.appointments;

    if (searchQuery) {
      filteredAppointments = filteredAppointments.filter((appointment) =>
        appointment.service_id.title.toLowerCase().includes(searchQuery)
      );
    }

    if (selectedDate) {
      filteredAppointments = filteredAppointments.filter(
        (appointment) =>
          new Date(appointment.start_date).toDateString() ===
          selectedDate.toDateString()
      );
    }

    this.setState({ filteredAppointments, currentPage: 1, showResults: true }); // Show results after filtering
  };

  handleAddToCalendar = (title, date) => {
    const formattedTitle = encodeURIComponent(title);
    const formattedDate = new Date(date).toISOString().replace(/-|:|\.\d+/g, '');
    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${formattedTitle}&dates=${formattedDate}/${formattedDate}`;
    window.open(calendarUrl, "_blank");
};


  paginate = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { filteredAppointments, currentPage, eventsPerPage, eventDates } =
      this.state;

    // Pagination logic
    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = filteredAppointments.slice(
      indexOfFirstEvent,
      indexOfLastEvent
    );

    // Render pagination numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(filteredAppointments.length / eventsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    // Function to highlight dates with events
    const highlightDates = (date) => {
      const currentDate = new Date(date).setHours(0, 0, 0, 0);
      return eventDates.includes(currentDate);
    };

    return (
      <div>
        {/* <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Padel Appointments
        </h2> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <input
            type="text"
            placeholder="Search by Title"
            value={this.state.searchQuery}
            onChange={this.handleSearch}
            style={{ marginRight: "10px", border: '1px solid #273E2A' , width: '600px', height: '40px', padding: '10px'}}
          />
          <button style={{backgroundColor: '#273E2A', color:'white', padding: '15px 10px', borderRadius: '5px', border: '1px solid #273E2A', cursor: 'pointer'}} onClick={this.handleFindEvents}>Find Events</button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <DatePicker
            className="datepicker"
            selected={this.state.selectedDate}
            onChange={this.handleDateChange}
            dateFormat="MM/dd/yyyy"
            placeholderText="Select Date"
            onSelect={this.handleDateChange} // Handle filtering when date is selected
            highlightDates={highlightDates} // Highlight dates with events
          />
          <button
            onClick={this.handleFilterByMonth}
            style={{backgroundColor: '#273E2A', color:'white', padding: '15px 10px', borderRadius: '5px', border: '1px solid #273E2A', cursor: 'pointer', marginRight: "10px"}}
          >
            Filter by Month
          </button>
          <button
            onClick={this.handleFilterByDay}
            style={{ backgroundColor: '#273E2A', color:'white', padding: '15px 10px', borderRadius: '5px', border: '1px solid #273E2A', cursor: 'pointer', marginRight: "10px" }}
          >
            Filter by Day
          </button>
          <button style={{ backgroundColor: '#273E2A', color:'white', padding: '15px 10px', borderRadius: '5px', border: '1px solid #273E2A', cursor: 'pointer', marginRight: "10px" }} onClick={this.handleReset}>Reset</button>
        </div>
        {this.state.showResults && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {currentEvents.length > 0 ? (
              currentEvents.map((appointment) => (
                <div
                  key={appointment.id}
                  style={{
                    border: "1px solid #273E2A",
                    borderRadius: "10px",
                    padding: "30px",
                    margin: "10px",
                    width: "25%",
                    backgroundColor:'#273E2A',
                    color: 'white',
                  }}
                >
                  <h3 style={{fontSize: '25px', fontFamily: '"Hate Your Writing", Sans-serif', color: '#D1FF79' }}>{appointment.service_id.title}</h3>
                  <h4 style={{fontSize: '20px', lineHeight: '10px', fontFamily: '"Glacial", Sans-serif' }}>IDR{appointment.service_id.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                  <br />
                  <strong>Location:</strong> {appointment.staff_id.full_name}
                  <br />
                  <strong>Start Date:</strong> {appointment.start_date}
                  <br />
                  <strong>End Date:</strong> {appointment.end_date}
                  <br />
                  <br />
                  <button 
                    onClick={() => this.handleAddToCalendar(appointment.service_id.title, appointment.start_date)}
                    style={{
                      backgroundColor: '#D1FF79',
                      color:'#273E2A',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      border: '1px solid #D1FF79',
                      cursor: 'pointer',
                    }}
                  >
                    Add to Calendar
                  </button>
                </div>
              ))
            ) : (
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  margin: "10px",
                  width: "30%",
                  textAlign: "center",
                }}
              >
                No events available
              </div>
            )}
          </div>
        )}
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: '30px', width: '93.5%' }}>
              <a
                target="_blank"
                href="https://www.google.com/calendar/render?cid=webcal%3A%2F%2Fstaging.junglepadel.com%2F%3Fpost_type%3Dtribe_events%26ical%3D1%26eventDisplay%3Dlist"
              >
                <button style={{backgroundColor: 'white', color:'#273E2A', padding: '15px 10px', borderRadius: '5px', border: '1px solid #273E2A', cursor: 'pointer'}}>
                  Subscribe to calender
                </button>
              </a>
            </div> */}
        <ul
          style={{
            display: "flex",
            justifyContent: "center",
            listStyle: "none",
            padding: "0",
            marginTop: "20px",
          }}
        >
          {pageNumbers.map((number) => (
            <li
              key={number}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              <button style={{backgroundColor: 'white', color:'#273E2A', borderRadius: '5px', padding:'10px', cursor: 'pointer', border: '1px solid #273E2A'}} onClick={() => this.paginate(number)}>{number}</button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default PadelCard;
